import React from 'react';
import {DataGrid} from '@material-ui/data-grid';
import PropTypes from "prop-types";
import "../../../../style/loadAudit.css";
import {useTranslation} from "react-i18next";

class LoadAuditTable extends React.Component {
    static propTypes = {
        loadAudits: PropTypes.array.isRequired,
        height: PropTypes.number,
        distribution: PropTypes.number.isRequired,
    }

    getColumns = () => {
        const getWidth = (column) => {
            const field = FIELDS[column.id];
            return field?field.width:0;
        };

        const getHeader = ({column,}, rows) => (
            {
                field: column.id,
                headerName: column.name,
                hide: column.hidden,
                width: getWidth(column),
                flex: 0,
                cellClassName: (params) => {
                    if (rows[params.rowIndex].tailtip) {
                        return 'red';
                    }
                    return column.className;
                },
                sortable: true,
            }
        );

        const {loadAudits, distribution} = this.props;
        const loadSequenceAuditColumns = loadAudits[distribution - 1].loadSequenceAuditColumn;
        const loadSequenceAuditRows = loadAudits[distribution - 1].loadSequenceAuditRow;
        const columns = [];
        if (loadSequenceAuditColumns) {
            loadSequenceAuditColumns.forEach((column) => {
                columns.push(getHeader({column}, loadSequenceAuditRows));
            });
        }
        return columns;
    };

    getRows = () => {
        const {loadAudits, distribution} = this.props;
        const loadSequenceAuditRows = loadAudits[distribution - 1].loadSequenceAuditRow;
        const rows = [];
        if (loadSequenceAuditRows) {
            loadSequenceAuditRows.forEach((loadSequenceAuditRow) => {
                const loadSequenceAuditCells = loadSequenceAuditRow.loadSequenceAuditCell;
                const loadSequenceAuditCell = loadSequenceAuditCells.reduce((obj, item) => {
                    return { ...obj,
                        [item['id']]: this.getCellValue(loadSequenceAuditRow, item)};
                }, {id: loadSequenceAuditRow.rowNumber});
                rows.push(loadSequenceAuditCell);
            });
        }
        return rows;
    };

    getCellValue(loadSequenceAuditRow, item) {
        return this.isTailTipAndActionCell(loadSequenceAuditRow, item) ? this.getTailtipWarning(item) : item['value'];
    }

    isTailTipAndActionCell(loadSequenceAuditRow, item) {
        return loadSequenceAuditRow.tailtip && item['id'] === "action";
    }

    getTailtipWarning(item) {
        return item['value'] + " (" + this.props.t("communicator.loadHistory.warning.tailtip") + ")";
    }

    render() {
        const {height, } = this.props;
        const rows = this.getRows();
        const columns = this.getColumns();
        return (
            <div style={{display: 'flex', height: height, width: '100%'}}>
                <div style={{ flexGrow: 1 }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        headerHeight={20}
                        rowHeight={20}
                        hideFooter={rows.length<=100}
                        disableColumnReorder={false}
                        rowsPerPageOptions={[100]}
                    />
                </div>
            </div>
        );
    }
}

const FIELDS = {
    'action': { width: 180, },
    'position': { width: 100},
    'uld': { width: 120},
    'time': { width: 190},
    'user': { width: 300},
    'pctMac': { width: 100},
    'pctMacMax': { width: 130},
    'uldWeight': { width: 140},
    'door': { width: 200},
    'aircraftWeight': { width: 160},
};

function withTranslation(Component) {
    return function WrappedComponent(props) {
        const { t } = useTranslation();
        return <Component {...props} t={t} />;
    }
}

export default withTranslation(LoadAuditTable);